import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {AngularFireModule} from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { environment } from 'src/environments/environment';
import {AngularFireAuthModule} from 'angularfire2/auth';
import { MenuComponent } from './components/menu/menu.component';
import { AngularFireStorageModule } from '@angular/fire/storage';


@NgModule({
  declarations: [AppComponent, MenuComponent, ],
  entryComponents: [],
  imports: [BrowserModule,IonicModule.forRoot() ,AppRoutingModule, AngularFireModule.initializeApp(environment.firebaseConfig),AngularFirestoreModule, AngularFireAuthModule, AngularFireStorageModule ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy, }],
  bootstrap: [AppComponent, ],
})
export class AppModule {}


