import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  constructor(private router: Router) { }
  opciones = [
    {
      name:'Inicio',
      id: 1
    },
    {
      name: 'apps',
      id: 3
    },
    {
      name: 'tecnologias',
      id: 4
    },
    {
      name: 'contacto',
      id: 5
    },
    {
      name: 'blog',
      id: 6
    }
  ];
  ngOnInit() {}
  goTo(data){
    if(data == 'Inicio'){
      data = 'home';
    }
    this.router.navigateByUrl('/'+data);
  }
}
