
export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyB24vWKEx6BdgGCFQcnJr5VkEFFJwyQWRo",
    authDomain: "webpage-bb3de.firebaseapp.com",
    projectId: "webpage-bb3de",
    storageBucket: "webpage-bb3de.appspot.com",
    messagingSenderId: "1091458616194",
    appId: "1:1091458616194:web:a466937da45b5c68329d9b",
    measurementId: "G-HVVQBDC7WY"
  }
};

